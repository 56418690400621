// TODO
// - Update background color based on time
// - Add more clear controls to toggle between dynamic and static 

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { format } from 'date-fns'
import { useInterval } from 'react-use';
import classnames from 'classnames'

/* Import Global Components */
import Page from "~components/page"

/* Import Local Styles */
import "./invisible-hand.scss"

/* Import Local PNG Assets */
import ImageOneSrc from "./assets/1.png"
import ImageTwoSrc from "./assets/2.png"
import ImageThreeSrc from "./assets/3.png"
import ImageFourSrc from "./assets/4.png"
import ImageFiveSrc from "./assets/5.png"
import ImageSixSrc from "./assets/6.png"
import ImageSevenSrc from "./assets/7.png"
import ImageEightSrc from "./assets/8.png"
import ImageNineSrc from "./assets/9.png"
import ImageTenSrc from "./assets/10.png"
import ImageElevenSrc from "./assets/11.png"
import ImageTwelveSrc from "./assets/12.png"

/* Import Local WEBP Assets */
// TODO

const OverrideTemplate = ({ data: { sanityPost: post } }) => {
  const images = [ImageOneSrc, 
  ImageOneSrc, 
    ImageTwoSrc, 
    ImageTwoSrc, 
    ImageThreeSrc, 
    ImageThreeSrc, 
    ImageFourSrc, 
    ImageFourSrc, 
    ImageFiveSrc, 
    ImageFiveSrc, 
    ImageSixSrc, 
    ImageSixSrc, 
    ImageSevenSrc, 
    ImageSevenSrc, 
    ImageEightSrc, 
    ImageEightSrc, 
    ImageNineSrc, 
    ImageNineSrc, 
    ImageTenSrc, 
    ImageTenSrc, 
    ImageElevenSrc, 
    ImageElevenSrc, 
    ImageTwelveSrc, 
    ImageTwelveSrc
  ]

  const imageData = post.heroImage
    ? getGatsbyImageData(
        post.heroImage.asset.id,
        {
          maxWidth: 1200,
          maxHeight: 630,
          toFormat: "png",
        },
        { projectId: "2pq7mrv0", dataset: "production" }
      )
    : null
  const [time, setTime] = useState(new Date())
  const [displayMode, setDisplayMode] = useState('dynamic')
  const [imageIndex, setImageIndex] = useState()

  useEffect(() => {
    setImageIndex(time.getHours())
  }, [])

  useInterval(
    () => {
      setTime(new Date());
    },
    1000
  );

  const toggleDisplayMode = () => {
    if(displayMode === "dynamic") {
      setDisplayMode('static')
    } else {
      setDisplayMode('dynamic')
    }
  }

  return (
    <Page
      title={post.postTitle}
      className={'invisible-hand'}
      description={post.postExcerpt}
      article={true}
      authors={post.postAuthor}
      datePublished={post.publicationDate}
      image={imageData ? imageData.images.fallback.src : null}
    >
      <div className={classnames('dynamic-content', {['visible']: displayMode === "dynamic"})}>
        <div className="featured-image">
          {images[imageIndex] && <img src={images[imageIndex]} />}
        </div>
        <div className="time">
          <p>{format(time, 'HH:mm:ss')}</p>
        </div>
        <button onClick={toggleDisplayMode} style={{width: 'min-content', margin: '0 auto', whiteSpace: 'nowrap'}}>Invisible Hand</button>
      </div>
      <div className={classnames('static-content', {['visible']: displayMode === "static"})}>
        <div className="photos">
          <div className="photo one">
            <img src={ImageOneSrc} />
          </div>
          <div className="photo two">
            <img src={ImageTwoSrc} />
          </div>
          <div className="photo three">
            <img src={ImageThreeSrc} />
          </div>
          <div className="photo four">
            <img src={ImageFourSrc} />
          </div>
          <div className="photo five">
            <img src={ImageFiveSrc} />
          </div>
          <div className="photo six">
            <img src={ImageSixSrc} />
          </div>
          <div className="photo seven">
            <img src={ImageSevenSrc} />
          </div>
          <div className="photo eight">
            <img src={ImageEightSrc} />
          </div>
          <div className="photo nine">
            <img src={ImageNineSrc} />
          </div>
          <div className="photo ten">
            <img src={ImageTenSrc} />
          </div>
          <div className="photo eleven">
            <img src={ImageElevenSrc} />
          </div>
          <div className="photo twelve">
            <img src={ImageTwelveSrc} />
          </div>          
        </div>
        <div className="writing">
          <h1 className="title">invisible hand</h1>
          <p>No man can serve two masters: for either he will hate the one, and love the other; or else he will hold to the one, and despise the other. Ye cannot serve God and mammon. - Matthew 6:24</p>
          <p>Driving down I-70 on the way to Colorado from Kansas City and staring out the window at the endless rolling subsidized soybean fields, you’re struck with twofold moments of sublime beauty and tedium. Few things are more sublime than the rows and rows of windmills you pass. They're massive, ominous creatures, their blades designed to mimic the shapes of whale’s fins cutting through water/air. You can never tell how tall they are from the highway, but you can tell they’re huge, out there, in the distance.</p>
          <p>No one has seen the true face of God. Witnessing American Capital all at once is an impossible task. To do so would shatter the mind of anyone who dare attempt it. These two binaries rule America. Religion and Capital. How can we understand the omnipotence of God? How can we understand the invisible hands of the market? They say God works in mysterious ways.</p>
          <p>The United States is a place of sublime and terrific beauty. The country’s landmass is huge and all-throughout the effects of capital and religion define and shape every physical, metaphysical and material aspect of our lives. The factories and churches that surround us are the homes of Gods. These factories and churches tend to be cavernous, massive buildings; These are not buildings for humans to live in. They are not on a human scale. They are not built for us.</p>
          <p>American Protestantism and Mormonism are perfect examples of the intersection of religion and capitalism in America. In Protestantism’s case, it represents the work ethic and devotion to both Christ and coin that defined this country’s history. In Mormonism’s case, it’s a vaguely Christian flavored grift that has an entire state to its hand — a symbol of westward expansion and settler colonialism. A fusion of god and capital into chimera.</p>
          <p>No coincidence, then, that you can see the Latter Day Saints’ tower from much of east Kansas City. They believed Independence was the City of Zion, a new Jerusalem that the chosen people would be gathered together to live at the end of history.</p> 
          <p>You know how the Midwest feels perpetually in a state of slow moving divine apocalypse, between tornado-twisted trees and endless expanses of dead grass and empty shipping facilities no one uses and the hollow moan of the earth you cannot escape and rusted factories killed off by deindustrialization and signs on the highway telling you Fetal Heartbeats Begin at Seven Weeks and endless train tracks and grain silos towering into the sky and the superfund site your friends like to skate at and empty strip malls and the cell phone tower disguised as a pine tree behind the Mcdonalds on 78th and and the hum of the factory at night you can hear from the levee and civil war battlefield you smoke weed at and the sheer liminality of it all and the windmills at the edge of oblivion and your Catholic grandmother doing the sign of the cross when she sees the Westboro Baptist Church people with signs on the street corner and if you want me again look for me under your bootsoles and another school abandoned from white flight being turned into luxury apartments and pipes pushing past a piss-pine and the squeal of cicadas and you’re doing graffiti by the BNSF line until it starts raining and the end of something but you can’t quite put your finger on it. Why is that?</p>
        </div>
        <button onClick={toggleDisplayMode} style={{width: 'min-content', margin: '0 auto', whiteSpace: 'nowrap'}}>Return</button>
      </div>
    </Page>
  )
}

export default OverrideTemplate

export const query = graphql`
  query OverrideQuery {
    sanityPost(id: { eq: "-8e7606ef-e365-51db-b056-58a34bf62ca5" }) {
      id
      _id
      slug
      postTitle
      postAuthor {
        _id
        name
        slug
        publishOnWebsite
      }
      publicationDate
      postSeries {
        _id
        title
        slug
      }
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      _rawPostSummary
      postExcerpt
      reports {
        _id
        slug
        publishOnWebsite
        title
      }
      heroImage {
        asset {
          id
        }
      }
    }
  }
`
